











import { Component, Provide, Watch } from 'vue-property-decorator'
import { QueryParams } from '@movecloser/front-core'
import { AbstractSelectControlOption } from '@movecloser/ui-core'
import { isPlainObject } from 'lodash'

import { IJobOffersRepository, JobOffersRepositoryType } from '../../../../repositories'
import { isUnresolvedLink, RelatedOption, RelatedType } from '../../../../contracts'
import { IUserComService, RelatedRecord, UserComServiceType } from '../../../../services'
import { JobOffer } from '../../../../models'
import { log } from '../../../../support'

import { BOOTSTRAP_PAGINATION_PROPS } from '../../../molecules/Pagination/Pagination.config'

import { AbstractModuleUi } from '../../_abstract'
import { ResolvedSearchAddon, SearchAddon } from '../../Hero/addons'

import { SearchResultsModule } from '../SearchResults.contracts'

/**
 * Container component for the `SearchResultsModuleUi`.
 *
 * TODO (some day): We could omit the first API call (inside the `loadOffers()` method) by reading
 * the job offers directly from the `RelatedService`. The thing is, they're already there (in the
 * related data dictionary) so there's no need for an additional API call. Of course, it might be
 * tricky and cost us some time, so it's better to do this during a more secure timeframe.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<SearchResultsModuleUi>({
  name: 'SearchResultsModuleUi',
  components: {
    ModuleUnavailablePlaceholder: () => import(
      /* webpackChunkName: "cms/frame" */
      '../../../molecules/ModuleUnavailablePlaceholder/ModuleUnavailablePlaceholder.vue'
    ),
    SearchResultsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/SearchResults" */
      './SearchResults.ui.presentation.vue'
    )
  }
})
export class SearchResultsModuleUi extends AbstractModuleUi<SearchResultsModule> {
  /**
   * Currently-active page of the search results (pagination).
   *
   * @see SearchResultsModuleUiPresentation._currentPage
   */
  public currentPage: number = 0

  /**
   * Determines whether the component is performing any async actions at a given moment.
   *
   * @see JobOffersTable.loading
   * @see NewsletterForm.loading
   */
  public isLoading: boolean = false

  /**
   * Array of the job offers fetched from the API.
   *
   * @see SearchResultsModuleUiPresentation.offers
   */
  public offers: JobOffer[] = []

  /**
   * Total number of search results.
   *
   * @see SearchResultsModuleUiPresentation.total
   */
  public total: number = 0

  /**
   * Number of elements per pages.
   *
   * @see SearchResultsModuleUiPresentation.perPage
   */
  public perPage: number = 0

  public departments: Array<AbstractSelectControlOption> = []
  public locations: Array<AbstractSelectControlOption> = []
  public jobsModels: Array<AbstractSelectControlOption> = []

  /**
   * @see JobOffersModuleUi.openLinkInNewTab
   */
  public get openLinkInNewTab (): boolean {
    return !!this.data.content.openLinkInNewTab
  }

  public get query (): QueryParams {
    return this.$route.query as QueryParams
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    try {
      await this.loadOffers()
      await this.loadRelatedData()
    } catch (error) {
      log(error, 'error')
    }
  }

  /**
   * Value for the `headingContent` @Prop of the `<SearchResultsModuleUiPresentation>`.
   *
   * @see SearchResultsModuleUiPresentation.headingContent
   */
  public get headingContent (): string | undefined {
    return this.data.content?.noResultsInfo?.heading?.text
  }

  /**
   * Value for the `headingLevel` @Prop of the `<SearchResultsModuleUiPresentation>`.
   *
   * @see SearchResultsModuleUiPresentation.headingLevel
   */
  public get headingLevel (): number | undefined {
    return this.data.content?.noResultsInfo?.heading?.level
  }

  /**
   * Value for the `intro` @Prop of the `<SearchResultsModuleUiPresentation>`.
   *
   * @see SearchResultsModuleUiPresentation.intro
   */
  public get intro (): string | undefined {
    return this.data.content?.noResultsInfo?.intro
  }

  /**
   * Handles the @update:currentPage event on the `<SearchResultsModuleUiPresentation>`.
   *
   * @param value - Value emitted by the event.
   *
   * @see currentPage
   */
  public async onCurrentPageChange (value: number): Promise<void> {
    if (typeof value !== 'number') {
      return
    }

    this.currentPage = value

    if (value.toString() !== this.$route.query.page) {
      this.isLoading = true

      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: value.toString()
        }
      })

      this.isLoading = false
    }
  }

  /**
   * An instance of the `UserComService`.
   */
  @Provide()
  public get userComService (): IUserComService | undefined {
    if (this.isCMSApp) {
      return
    }

    return this.resolveInjection<IUserComService>(UserComServiceType)
  }

  /**
   * An instance of the `JobOffersRepository`.
   */
  private get jobOffersRepository (): IJobOffersRepository {
    return this.resolveInjection<IJobOffersRepository>(JobOffersRepositoryType)
  }

  /**
   * Fetches the job offers from the API.
   */
  private async loadOffers (): Promise<void> {
    this.isLoading = true

    const perPage = BOOTSTRAP_PAGINATION_PROPS.perPage

    try {
      const offers = await this.jobOffersRepository.load(
        { perPage, ...this.query }
      )

      this.offers = offers

      // FIXME
      // @ts-expect-error - For some reason constructor new Collection add meta under `_meta` key
      // instead of `meta`
      const meta = offers._meta

      this.total = meta.total
      this.perPage = meta.per_page
      this.currentPage = Number(meta.current_page)
    } catch (error) {
      log(error, 'error')
    } finally {
      this.isLoading = false
    }
  }

  public async loadRelatedData (): Promise<void> {
    this.isLoading = true

    try {
      const { departments, locations, jobsModels } = await this.resolveSearchRelatedTypes()
      this.departments = departments
      this.locations = locations
      this.jobsModels = jobsModels ?? []
    } catch (error) {
      log(error, 'error')
    } finally {
      this.isLoading = false
    }
  }

  private async resolveSearchRelatedTypes (): Promise<Pick<ResolvedSearchAddon, 'departments' | 'locations' | 'jobsModels'>> {
    const relatedRecord: RelatedRecord = this.relatedService.getRecord()

    if (!isPlainObject(relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): Expected [relatedRecord] to be a plain object, but instead got [${typeof relatedRecord}]!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.DepartmentsOptions in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [departments] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.RegionsOptions in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [regions] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    if (!(RelatedType.JobsModel in relatedRecord)) {
      throw new Error(`HeroModuleUi.fetchSearchAddon(): [relatedRecord] is missing the [jobsModel] key!\n[relatedRecord] object: ${relatedRecord}`)
    }

    const departments: ResolvedSearchAddon['departments'] =
      (relatedRecord[RelatedType.DepartmentsOptions] as unknown as RelatedOption[])
        .map(({ id, name, option }) => ({
          label: option ?? name,
          value: id
        }))

    const locations: ResolvedSearchAddon['locations'] =
      (relatedRecord[RelatedType.RegionsOptions] as unknown as RelatedOption[])
        .map(({ id, name }) => ({
          label: name,
          value: id.toString()
        }))

    const jobsModels: ResolvedSearchAddon['jobsModels'] =
      Object.values((relatedRecord[RelatedType.JobsModel] as unknown as Record<string, string>))
        .map((option) => {
          return {
            label: this.$t(`_.jobModel.${option}`).toString(),
            value: option
          }
        })

    return { departments, locations, jobsModels }
  }

  @Watch('query')
  private onQueryChange (): void {
    this.loadOffers()
  }
}

export default SearchResultsModuleUi
