






























import { BootstrapIcon } from '@movecloser/ui-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<NoResultsInfo>({
  name: 'NoResultsInfo',
  components: {
    BootstrapIcon,
    NewsletterForm: () => import(
      /* webpackChunkName: "molecules/NewsletterForm" */
      '../../../../../molecules/NewsletterForm/NewsletterForm.vue'
    ),
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class NoResultsInfo extends Vue {
  /**
   * @see SearchResultsModuleContent.headingContent
   */
  @Prop({
    type: String,
    required: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    default (this: NoResultsInfo) {
      return this.$t('modules.components.modules.SearchResults.partials.NoResultsInfo.heading')
    }
  })
  public readonly headingContent?: string

  /**
   * @see SearchResultsModuleContent.headingLevel
   */
  @Prop({ type: Number, required: false, default: 2 })
  public readonly headingLevel?: number

  /**
   * @see SearchResultsModuleContent.intro
   */
  @Prop({
    type: String,
    required: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    default (this: NoResultsInfo) {
      return this.$t('modules.components.modules.SearchResults.partials.NoResultsInfo.intro')
    }
  })
  public readonly intro?: string

  /**
   * Determines whether the component has been provided with the correct `headingContent` @Prop.
   */
  public get hasHeadingContent (): boolean {
    return typeof this.headingContent === 'string' && this.headingContent.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `intro` @Prop.
   */
  public get hasIntro (): boolean {
    return typeof this.intro === 'string' && this.intro.length > 0
  }
}

export default NoResultsInfo
