














import { Component, Prop, Vue } from 'vue-property-decorator'

import { UiHeading } from '../../../../../atoms'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component({
  name: 'SearchHeader',
  components: { UiHeading }
})
export class SearchHeader extends Vue {
  /**
   * Total number of offers
   */
  @Prop({ type: Number, required: true })
  public readonly total!: number

  /**
   * Check is given query valid
   */
  public get hasQuery (): boolean {
    return typeof this.query === 'string' && this.query.length > 0
  }

  /**
   * Search query to show in header
   */
  public get query (): string {
    const query = this.$route.query.q
    return typeof query === 'string' ? query : ''
  }
}

export default SearchHeader
